import React, { useEffect, useState } from "react";
import bg from "assets/images/dashbg.jpeg";
import { useNavigate } from "react-router-dom";
import { User } from "shared/interfaces/User";
import { TbArrowBack } from "react-icons/tb";
import { BsPower } from "react-icons/bs";
import { get_api, get_req_api } from "shared/utils/callApiUtils";
import Swal from "sweetalert2";
import dayjs from "dayjs";

function HistoryDepositWithdraw() {
  let navigate = useNavigate();

  const [auth, setAuth] = useState(JSON.parse(localStorage.getItem("user")));
  const [transactions, setTransactions] = useState([]);

  const goToPreviousPage = () => {
    navigate(-1);
  };

  const logout = () => {
    localStorage.removeItem("user");

    document.cookie = "access_token= ";
    navigate("/");
  };

  const getHistoryTransaction = async () => {
    if (auth) {
      const response = await get_req_api(`users/${auth.id}`);
      // console.log({ response });
      if (response) {
        setTransactions(response?.data?.data?.bankTransactionLog);
      }
    }
  };

  const getProfile = () => {
    if (auth) {
      // const user = JSON.parse(auth);
      get_api(`users/profile`).then((result) => {
        if (result?.data?.status_code == "20000") {
          setAuth(JSON.stringify(result?.data?.data));
        } else {
          Swal.fire({
            // position: "top-end",
            icon: "error",
            title: "Session Expired",
            color: "#fff",
            showConfirmButton: true,
          }).then((result) => {
            navigate("/login");
          });
        }
      });
    }
  };

  useEffect(() => {
    if (auth) {
      getProfile();

      getHistoryTransaction();
    } else {
      Swal.fire({
        // position: "top-end",
        icon: "error",
        title: "Session Expired",
        color: "#fff",
        showConfirmButton: true,
      }).then((result) => {
        navigate("/login");
      });
    }

    return () => {
      setAuth({});
    };
  }, []);

  return (
    <div className="flex flex-col justify-center items-center bg-black h-screen w-screen">
      <div
        className={`flex flex-col lg:p-6 max-w-[604px] min-w-[300px] h-screen w-screen`}
        style={{
          backgroundImage: `url(${bg})`,
        }}
      >
        <div className="flex w-full justify-between items-start p-3 pb-0">
          <button
            className="text-white flex gap-2 items-center self-center mt-4"
            onClick={goToPreviousPage}
          >
            <TbArrowBack className="w-6 h-6 -scale-y-100" />
            กลับ
          </button>

          <button className="text-white mt-1" onClick={logout}>
            <span className="text-center text-lg text-white flex flex-col items-center">
              <BsPower className="text-primary-yellow w-6 h-6" />
              Logout
            </span>
          </button>
        </div>

        {/* <span className="text-primary-yellow-light text-xl text-center">
          ประวัติฝาก/ถอน
        </span> */}

        <div className="w-100 m-3 flex flex-col p-2 border border-white-100 rounded-lg bg-slate-600 max-h-96">
          <span className="text-lg text-yellow-400 text-center">
            ประวัติฝาก/ถอน
          </span>
        </div>

        <div className="overflow-y-auto p-3">
          {transactions &&
            transactions.map((item, index) => (
              <div className="grid grid-cols-3 p-3" key={index}>
                <span className="text-md text-left text-white mb-2">
                  {dayjs(item?.createdAt).format("DD-MM-YYYY hh:mm:ss")}
                </span>

                {item?.transactionType === "decrease_cash" && (
                  <>
                    <span className="text-md text-center text-white mb-2">
                      ถอนเงิน
                    </span>
                    <span className="text-md text-right text-red-600 mb-2">
                      {item?.amount}
                    </span>
                  </>
                )}
                {item?.transactionType === "increase_cash" && (
                  <>
                    <span className="text-md text-center text-white mb-2">
                      เติมเงิน
                    </span>
                    <span className="text-md text-right text-green-600 mb-2">
                      {item?.amount}
                    </span>
                  </>
                )}
                {item?.transactionType === "increase_cash_advance" && (
                  <>
                    <span className="text-md text-center text-white mb-2">
                      เติมเงินพิเศษ
                    </span>
                    <span className="text-md text-right text-blue-600 mb-2">
                      {item?.amount}
                    </span>
                  </>
                )}
              </div>
            ))}
        </div>
      </div>
    </div>
  );
}

export default HistoryDepositWithdraw;
