import React, { useEffect, useState, useRef } from "react";
import bg from "assets/images/dashbg.jpeg";
import { useNavigate } from "react-router-dom";
import { User } from "shared/interfaces/User";
import { TbArrowBack } from "react-icons/tb";
import { BsPower } from "react-icons/bs";
import CircleContainer from "components/CircleContainer";
import { FaSyncAlt } from "react-icons/fa";
import { get_api, get_req_api, post_api } from "shared/utils/callApiUtils";
import Swal from "sweetalert2";
import dayjs from "dayjs";
import { useCopyToClipboard } from "usehooks-ts";
import LinkOverall from "assets/images/link_overall.png";
import LinkIncome from "assets/images/link_income.png";
import LinkProfile from "assets/images/link_profile.png";
import LinkRefund from "assets/images/link_refund.png";
import LinkStat from "assets/images/link_stat.png";
import LinkWithdraw from "assets/images/link_withdraw.png";
import { CopyToClipboard } from "react-copy-to-clipboard";

function Affiliate() {
  let navigate = useNavigate();
  let hrefOrigin = useRef<any>();

  const [profile, setProfile] = useState<User>(
    JSON.parse(localStorage.getItem("user"))
  );
  const [affiliateUrl, setAffiliateUrl] = useState("");
  const [childList, setChildList] = useState([]);
  const [totalChildList, setTotalChildList] = useState(0);
  const [percentCut, setPercentCut] = useState(0);
  const [totalIncomeThisMonth, setTotalIncomeThisMonth] = useState(0);
  const [incomeDetailList, setIncomeDetailList] = useState([]);
  const [incomeLastMonth, setIncomeLastMonth] = useState(0);
  const [withdrawHistoryItems, setWithdrawHistoryItems] = useState([]);
  const today = dayjs().toISOString;
  const [month, setMonth] = useState(dayjs());
  const [mode, setMode] = useState("overall"); //overall, income, member, statistic, withdraw
  const [statisticView, setStatisticView] = useState("today");
  const tableStyle = {
    marginTop: '2dvh',
    border: 'solid 0.3dvh #ffffff15',
    backgroundColor: '#000000',
    width: '95%',
    height: '3.4dvh'
  }
  const goToPreviousPage = () => {
    navigate(-1);
  };
  const getProfile = async () => {
    await get_api(`users/profile`).then((result) => {
      if (result?.data?.status_code == "20000") {
        setProfile(result?.data?.data);

        hrefOrigin.current = window.location.origin;
        // const domain = window.location.origin;
        // console.log(domain);
        setAffiliateUrl(
          `${hrefOrigin.current}?refId=${result?.data?.data?._id}`
        );
      } else {
        Swal.fire({
          // position: "top-end",
          icon: "error",
          title: "Session Expired",
          color: "#fff",
          showConfirmButton: true,
        }).then((result) => {
          navigate("/login");
        });
      }
    });

    const response = await get_req_api(`affiliate/${profile?._id}`);
    // console.log("Affilate by userId", { response });
    if (response?.data?.status_code === "20000") {
      setChildList(response?.data?.data?.childList || []);
      setTotalChildList(response?.data?.data?.childList?.length || 0);
      setPercentCut(response?.data?.data?.percentCut);
      setTotalIncomeThisMonth(response?.data?.data?.totalIncomeThisMonth);
      setIncomeDetailList(response?.data?.data?.incomeInfoPerUserThisMonth);
    }
  };
  const TodayDate: React.FunctionComponent = () => {
    const today = new Date();
    // Arrow function to return the current date
    const getTodaysDate = (): string => {
      return today.toLocaleDateString();
    };
  
    return (
      <div>
        <p>{getTodaysDate()}</p>
      </div>
    );
  };
  const getAffiliateInfo = async () => {
    const new_month = dayjs(month).month();
    const response = await get_api(
      `affiliate/incomebymonth/${profile._id}?month=${new_month}`
    );
    // console.log("Affiliate this month", { response });
    if (response?.data?.status_code === "20000") {
      setIncomeLastMonth(response?.data?.data?.totalIncomeThisMonth);
    }
  };

  const withdrawHistory = async () => {
    const response = await get_api(`affiliate/withdraw-history`);
    // console.log("Affiliate withdraw history", { response });
    if (response?.data?.status_code === "20000") {
      setWithdrawHistoryItems(response.data.data);
      //   setWithdrawHistoryItems([]);
    }
  };

  const requestWithdraw = () => {
    Swal.fire({
      icon: "warning",
      text: "กรุณายืนยันการถอนเงิน!",
      color: "#fff",
      showCancelButton: true,
      confirmButtonText: "ยืนยัน",
      //   confirmButtonColor: "#2c0a06",
      denyButtonText: `ยกเลิก`,
    }).then(async (result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        const req = {};
        const response = await post_api(`affiliate/withdraw`, req);
        // console.log({ response });
        if (response?.data?.status_code === "20000") {
          Swal.fire({
            // position: "top-end",
            icon: "success",
            title: `ส่งยอดขอถอนรายได้เรียบร้อยแล้ว`,
            text: "",
            color: "#fff",
            showConfirmButton: false,
            timer: 1500,
          }).then(() => {
            // setLoading(false);
            // window.location.reload();
          });
        } else {
          // setLoading(false);
          Swal.fire(`ผลการถอนเงินไม่สำเร็จ`, ``, "error");
        }
      }
    });
  };

  useEffect(() => {
    withdrawHistory();
  }, [month]);

  useEffect(() => {
    getAffiliateInfo();
  }, [month]);

  useEffect(() => {
    if (profile) {
      getProfile();
    } else {
      Swal.fire({
        // position: "top-end",
        icon: "error",
        title: "Session Expired",
        color: "#fff",
        showConfirmButton: true,
      }).then((result) => {
        navigate("/login");
      });
    }

    return () => {
      setProfile(undefined);
    };
  }, []);

  return (
    <div className="flex flex-col justify-center items-center bg-black">
      <div
        className={`flex flex-col`}
        style={{
          minHeight: "100vh",
          maxWidth: "430px",
          width: "100%",
          backgroundImage: `url(${bg})`,
        }}
      >
        <div className="flex w-full justify-between items-start p-3 pb-0">
          <button
            className="text-white flex gap-2 items-center self-center mt-4"
            onClick={goToPreviousPage}
          >
            <TbArrowBack className="w-6 h-6 -scale-y-100" />
            กลับ
          </button>
        </div>

        <div className="grid grid-cols-3 gap-4 p-10">
          <div
            className="w-100 flex flex-col items-center cursor-pointer rounded-md p-2 hover:bg-gray-700"
            onClick={() => setMode("overall")}
          >
            <img width={40} src={LinkProfile} alt="" />
            <span className="text-md text-yellow-500 text-center">ภาพรวม</span>
          </div>
          <div
            className="w-100 flex flex-col items-center cursor-pointer rounded-md p-2 hover:bg-gray-700"
            onClick={() => setMode("income")}
          >
            <img width={40} src={LinkIncome} alt="" />
            <span className="text-md text-yellow-500 text-center">รายได้</span>
          </div>
          <div
            className="w-100 flex flex-col items-center cursor-pointer rounded-md p-2 hover:bg-gray-700"
            onClick={() => setMode("member")}
          >
            <img width={40} src={LinkProfile} alt="" />
            <span className="text-md text-yellow-500 text-center">สมาชิก</span>
          </div>
          <div
            className="w-100 flex flex-col items-center cursor-pointer rounded-md p-2 hover:bg-gray-700"
            onClick={() => setMode("statistic")}
          >
            <img width={40} src={LinkStat} alt="" />
            <span className="text-md text-yellow-500 text-center">สถิติ</span>
          </div>
          <div
            className="w-100 flex flex-col items-center cursor-pointer rounded-md p-2 hover:bg-gray-700"
            onClick={() => setMode("withdraw")}
          >
            <img width={40} src={LinkWithdraw} alt="" />
            <span className="text-md text-yellow-500 text-center">
              ถอนรายได้
            </span>
          </div>
          <div
            className="w-100 flex flex-col items-center cursor-pointer rounded-md p-2 hover:bg-gray-700"
            style={{
              filter: "grayscale(100%)!important",
              opacity: 0.5,
              pointerEvents: "none",
            }}
          >
            <img width={40} src={LinkRefund} alt="" />
            <span className="text-md text-yellow-500 text-center">
              คืนยอดเสีย
            </span>
          </div>
        </div>

        {mode === "overall" && (
          <>
            <div className="flex flex-col text-white">
              <span className="text-lg text-center mb-3">ลิงค์รับทรัพย์</span>
              <div className="flex items-stretch justify-center flex-wrap relative w-full">
                <input
                  style={{
                    border: "1px solid #ced4da",
                    borderRadius: "30px",
                    color: "#495057",
                    display: "block",
                    width: "75%",
                    fontSize: ".875rem",
                    fontWeight: 400,
                    // height: "calc(1.5em + 1.6rem + 2px)",
                    // lineHeight: "1.5",
                    padding: "0.4rem",
                    transition:
                      "border-color .15s ease-in-out,box-shadow .15s ease-in-out",
                    background: "rgba(0,0,0,.5)!important",
                    borderBottomRightRadius: 0,
                    borderTopRightRadius: 0,
                    borderTopLeftRadius: "50px",
                    borderBottomLeftRadius: "50px",
                  }}
                  value={affiliateUrl}
                  readOnly
                />
                <CopyToClipboard
                  text={affiliateUrl}
                  onCopy={() => {
                    Swal.fire({
                      text: "คัดลอกแล้ว",
                      showCloseButton: false,
                      showConfirmButton: false,
                      timer: 1000,
                      width: "50%",
                      background: "linear-gradient(180deg,#27006e,#190050)",
                      color: "#fff",
                    });
                  }}
                >
                  <span
                    style={{
                      padding: "0.4rem",
                      lineHeight: "1.5",
                      background: "linear-gradient(180deg,#ffe640,#ffa200)",
                      border: "none!important",
                      borderRadius: "50px",
                      borderBottomLeftRadius: 0,
                      borderTopLeftRadius: 0,
                      marginLeft: "-1px",
                      cursor: "pointer",
                    }}
                  >
                    คัดลอก
                  </span>
                </CopyToClipboard>
              </div>
            </div>

            <div className="w-100 bg-purple-500 mt-5 flex flex-col items-center p-2">
              <span className="text-white text-lg">รายได้ผู้แนะนำ</span>
            </div>
            <div className="flex flex-col items-center text-white text-md p-2">
              <span>สมัคร</span>
              <span className="mt-5">{childList?.length} คน</span>
            </div>
            <div className="grid grid-cols-2 gap-4 p-2">
              <div className="flex flex-col items-center text-white text-md p-2 border">
                <span>รายได้ทั้งหมด</span>
                <span className="mt-5">
                  {totalIncomeThisMonth?.toLocaleString() || 0} บาท
                </span>
              </div>
              <div className="flex flex-col items-center text-white text-md p-2 border">
                <span>รายได้ที่ถอน</span>
                <span className="mt-5">
                  {withdrawHistoryItems[0]?.amount?.toLocaleString() || 0} บาท
                </span>
              </div>
            </div>
          </>
        )}

        {mode === "income" && (
          <>
            <div className="flex flex-col text-white">
              <span className="text-lg text-center mb-3">รายได้</span>
            </div>

            <div className="grid grid-cols-2 gap-4 p-2">
              <div className="flex flex-col items-center text-white text-md p-2 border">
                <span>รายได้ทั้งหมด</span>
                <span className="mt-5">
                  {totalIncomeThisMonth?.toLocaleString() || 0} บาท
                </span>
              </div>
              <div className="flex flex-col items-center text-white text-md p-2 border">
                <span>รายได้ที่ถอน</span>
                <span className="mt-5">
                  {withdrawHistoryItems[0]?.amount?.toLocaleString() || 0} บาท
                </span>
              </div>
            </div>

            <div className="w-100 bg-purple-500 mt-5 mb-5 flex flex-col items-center p-2">
              <span className="text-white text-lg">แพ้ / ชนะ</span>
            </div>
            <div className="flex flex-col items-center text-white text-md m-2 p-2 border">
              <span>รายได้</span>
              <span className="mt-5">
                {totalIncomeThisMonth?.toLocaleString() || 0} บาท
              </span>
            </div>
            <div className="flex flex-col items-center text-white text-md m-2 p-2 border">
              <span>เปอร์เซ็นต์</span>
              <span className="mt-5">{percentCut} เปอร์เซ็นต์</span>
            </div>
          </>
        )}

        {mode === "member" && (
          <>
            <div className="flex flex-col text-white">
              <span className="text-lg text-center mb-3">สมาชิก</span>
            </div>

            <div className="w-100 m-3 flex flex-col p-2 border border-white-100 rounded-lg bg-slate-600 max-h-96 overflow-x-scroll">
              <div className="grid grid-cols-2 gap-4 text-white">
                <span className="text-center text-yellow-400">
                  ชื่อผู้ใช้งาน
                </span>
                <span className="text-center text-yellow-400">วันที่สมัคร</span>
                {childList.map((child, index) => (
                  <>
                    <span className="text-center">{child?.username}</span>
                    <span className="text-center">
                      {dayjs(child?.createdAt).format("DD-MM-YYYY")}
                    </span>
                  </>
                ))}
              </div>
            </div>
          </>
        )}

        {mode === "withdraw" && (
          <>
            <div className="flex flex-col text-white">
              <span className="text-lg text-center mb-3">ถอนรายได้</span>
            </div>

            <div className="w-100 m-3 flex flex-col justify-center p-2 border border-white-100 rounded-lg bg-slate-600">
              <div className="grid grid-cols-3">
                <span className="text-lg text-yellow-400">รายได้ทั้งหมด</span>
                <span className="text-lg text-center text-white ml-5">
                  {/* {withdrawHistoryItems[0]?.amount?.toLocaleString() || 0} */}
                  {incomeLastMonth?.toLocaleString() || 0} บาท
                </span>
                <button
                  className="rounded-full bg-gradient-to-b from-primary-yellow-light to-primary-yellow-dark"
                  onClick={requestWithdraw}
                >
                  ถอนเงิน
                </button>
              </div>
            </div>

            <div className="w-100 m-3 flex flex-col p-2 border border-white-100 rounded-lg bg-slate-600 max-h-96 overflow-x-scroll">
              <span className="text-lg text-yellow-400 text-center">
                รายการถอน
              </span>
              <div className="grid grid-cols-2">
                {withdrawHistoryItems.length > 0 &&
                  withdrawHistoryItems.map((item, index) => (
                    <>
                      <span className="text-md text-center text-white ml-5">
                        {item?.fromBankAccNumber}
                      </span>
                      <span className="text-md text-center text-white ml-5">
                        {item?.amount}
                      </span>
                    </>
                  ))}
              </div>
            </div>
          </>
        )}
        {mode === "statistic" && (
          <div className="items-center">
            <div className="flex flex-col text-white">
              <span className="text-lg text-center mb-3">สถิติ</span>
            </div>
            <div className="flex flex-col items-center text-white text-md p-2 border rounded-lg">
              <div className="flex flex-row" style={{justifyContent:'center',columnGap:'1.5dvh',marginBottom:'2dvh',marginTop:'1.5dvh'}}>
                <input type="radio" value="today" name="selecter" onClick={()=> setStatisticView("today")} /> วันนี้
                <input type="radio" value="week" name="selecter" onClick={()=> setStatisticView("week")} /> สัปดาห์
                <input type="radio" value="month" name="selecter" onClick={()=> setStatisticView("month")} /> เดือน
              </div>
              <button type="button" style={{backgroundColor:'green',paddingLeft:10,paddingRight:10,borderRadius:10}} onClick={()=>console.log(incomeDetailList)}>ค้นหา</button>
            </div>
            <div className="flex items-center justify-center my-5">
              <table style={{width:'100%'}} className="rounded-md table-auto">
                <thead style={tableStyle} className="text-sm text-yellow-500/75">
                  <tr>
                    <th>ชื่อผู้ใช้</th>
                    <th>วันที่เล่น</th>
                    <th>แพ้/ชนะ</th>
                  </tr>
                </thead>
                <tbody>
                {
                  incomeDetailList != null && incomeDetailList.length > 0 && incomeDetailList.map((item, index) => (
                    <tr className="text-white">
                      <td className="flex justify-center">
                        {childList.find(el=>el.userId == item?.userID).username}
                      </td>
                      <td>
                        <TodayDate/>
                      </td>
                      <td className="flex justify-center">
                        {item?.AffiliateSummary}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Affiliate;
