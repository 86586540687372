import React from 'react'

function Button(props: ButtonProps) {
  return (
    <button className={`text-[#fff] font-medium bg-gradient-to-b from-primary-yellow-light to-primary-yellow-dark rounded-full border-2 border-[#f4b731] py-2 ${props.className}`} onClick={props?.onClick}>
        {props.children}
    </button>
  )
}

interface ButtonProps {
    children: React.ReactNode
    className?: string
    link?: string
    onClick?: () => void
}

export default Button