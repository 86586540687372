import SquareContainer from "components/SquareContainer";
import React from "react";
import { Providers } from "shared/interfaces/Providers";

function RightGames({
  category,
  games,
}: {
  category: string;
  games: Providers[];
}) {
  return (
    <div className="grid grid-cols-4 lg:grid-cols-6 gap-4 grow mx-3 lg:ml-20 xl:ml-28">
      {games.map((game, index) => {
        if (category === "all" || game.category === category) {
          return (
            <SquareContainer
              key={index}
              providerName={game.gameProvider}
              title={game.category}
              subGame={false}
            >
              <img
                src={game.gameDisplay2 ? game.gameDisplay2 : game.gameLogo}
                alt={game.gameDisplay2 ? game.gameDisplay2 : game.gameLogo}
                className="w-16 lg:w-24 h-16 lg:h-24 rounded-xl"
              />
            </SquareContainer>
          );
        }
      })}
    </div>
  );
}

export default RightGames;
