import BetGameTv from "../../assets/images/icon_gameprovider/betgames.tv.png";
import BigGamming from "../../assets/images/icon_gameprovider/biggaming.png";
import CQ9 from "../../assets/images/icon_gameprovider/cq9.png";
import EvoPlay from "../../assets/images/icon_gameprovider/evo.png";
import KingMaker from "../../assets/images/icon_gameprovider/kingmaker.png";
import JILI from "../../assets/images/icon_gameprovider/jili.png";
import KAGaming from "../../assets/images/icon_gameprovider/kagaming.png";
import HANABERO from "../../assets/images/icon_gameprovider/habanero.png";
import MICRO_GAMMING from "../../assets/images/icon_gameprovider/microgaming.png";
import NETENT from "../../assets/images/icon_gameprovider/netent.png";
import PGSLOT from "../../assets/images/icon_gameprovider/pgslot.png";
import PRAGMATIC from "../../assets/images/icon_gameprovider/ppcasino.png";
import PRAGMATIC_SLOT from "../../assets/images/icon_gameprovider/ppslot.png";
import PRETTY_GAMMING from "../../assets/images/icon_gameprovider/prettygaming.png";
import RED_TIGER_GAMING from "../../assets/images/icon_gameprovider/redtiger.png";
import RELAX_GAMING from "../../assets/images/icon_gameprovider/relexgame.png";
import SA_GAMING from "../../assets/images/icon_gameprovider/sagaming.png";
import SBO from "../../assets/images/icon_gameprovider/sbo.png";
import YGGDRASIL from "../../assets/images/icon_gameprovider/ygg.png";
import AG from "../../assets/images/icon_gameprovider/ag.png";
import BCOONGO from "../../assets/images/icon_gameprovider/bcoongo.png";
import ELK from "../../assets/images/icon_gameprovider/elk-studio.png";
import FANTASMA from "../../assets/images/icon_gameprovider/fantasma.png";
import GAMEART from "../../assets/images/icon_gameprovider/gameart.png";
import GAME_FISH_GLOBAL from "../../assets/images/icon_gameprovider/gamefish-global.png";
import GAMZIX from "../../assets/images/icon_gameprovider/gamzix.png";
import IRON_DOG from "../../assets/images/icon_gameprovider/irondog.png";
import KALAMBA from "../../assets/images/icon_gameprovider/kalamba-games.png";
import KIRON from "../../assets/images/icon_gameprovider/kiron.png";
import MOBILOT from "../../assets/images/icon_gameprovider/mobilots.png";
import ONETOUCH from "../../assets/images/icon_gameprovider/onetouch.png";
import PLAYNGO from "../../assets/images/icon_gameprovider/playngo.png";
import PRINT_STUDIO from "../../assets/images/icon_gameprovider/printstudio.png";
import PUSH_GAMING from "../../assets/images/icon_gameprovider/pushgaming.png";
import SLOT_MILL from "../../assets/images/icon_gameprovider/slotmill.png";
import THUNDER_KICK from "../../assets/images/icon_gameprovider/thunderkick.png";
import SLOTXO from "../../assets/images/icon_gameprovider/slotxo.png";
import SEXY_GAMING from "../../assets/images/icon_gameprovider/CasinoImgSexyGaming.png"
import JOKER_GAME from "../../assets/images/icon_gameprovider/JokerGaming.png"
import Dream2 from "../../assets/images/icon_gameprovider/dreamgaming.png"
import ALLBET_GAME from "../../assets/images/icon_gameprovider/AllbetLogo.jpg"
import SPINIX from "../../assets/images/icon_gameprovider/spinx.png"

import TMP_LOGO from "../../assets/images/icon_gameprovider/tmp-logo.png";
import AMEBA from "../../assets/images/icon_gameprovider/amebaentertainment.png";
import AMBSLOT from "../../assets/images/icon_gameprovider/ambslot.png";
import SPADE from "../../assets/images/icon_gameprovider/spadegaming.png";
import XTREME from "../../assets/images/icon_gameprovider/xg.png";




export const casino = [
  
  {
    provider_name: "BIGGAME",
    category: "casino",
    display_name: "",
    bg_image: BigGamming,
  },
  {
    provider_name: "PRETTY",
    category: "casino",
    display_name: "",
    bg_image: PRETTY_GAMMING,
  },
  {
    provider_name: "SAGAME",
    category: "casino",
    display_name: "",
    bg_image: SA_GAMING,
  },
  {
    provider_name: "SEXY",
    category: "casino",
    display_name: "",
    bg_image: SEXY_GAMING,
  },
  {
    provider_name: "XTREME",
    category: "casino",
    display_name: "",
    bg_image: XTREME,
  },
  
  {
    provider_name: "DREAM2",
    category: "casino",
    display_name: "",
    bg_image: Dream2,
  },
  {
    provider_name: "ALLBET",
    category: "casino",
    display_name: "",
    bg_image: ALLBET_GAME,
  },
  
  
  



  
  
  
];

export const slot = [
  // AMB
  {
    provider_name: "PGSOFT2",
    category: "slot",
    display_name: "",
    bg_image: PGSLOT,
  },
  {
    provider_name: "AMEBA",
    category: "slot",
    display_name: "",
    bg_image: AMEBA,
  },
  {
    provider_name: "ASKMEBET",
    category: "slot",
    display_name: "",
    bg_image: AMBSLOT,
  },

  {
    provider_name: "EVOPLAY",
    category: "slot",
    display_name: "",
    bg_image: EvoPlay,
  },
  {
    provider_name: "JILI",
    category: "slot",
    display_name: "",
    bg_image: JILI,
  },
  {
    provider_name: "JOKER",
    category: "slot",
    display_name: "",
    bg_image: JOKER_GAME,
  },

  {
    provider_name: "KINGMAKER2",
    category: "slot",
    display_name: "",
    bg_image: KingMaker,
  },
  {
    provider_name: "PRAGMATIC_SLOT",
    category: "slot",
    display_name: "",
    bg_image: PRAGMATIC_SLOT,
  },
  {
    provider_name: "SLOTXO",
    category: "slot",
    display_name: "",
    bg_image: SLOTXO,
  },
  {
    provider_name: "SPADE",
    category: "slot",
    display_name: "",
    bg_image: SPADE,
  },

  {
    provider_name: "NETENT2",
    category: "slot",
    display_name: "",
    bg_image: NETENT,
  },
  {
    provider_name: "REDTIGER",
    category: "slot",
    display_name: "",
    bg_image: RED_TIGER_GAMING,
  },
  {
    provider_name: "SPINIX",
    category: "slot",
    display_name: "",
    bg_image: SPINIX,
  },
  
  
  
];

export const fishing = [
  {
    provider_name: "JILI",
    category: "fishing",
    display_name: "",
    bg_image: JILI,
  },
  {
    provider_name: "CQ9V2",
    category: "fishing",
    display_name: "",
    bg_image: CQ9,
  },
  
];

export const sport = [
  // {
  //   provider_name: "SBOSport",
  //   category: "sport",
  //   display_name: "",
  //   bg_image: SBO,
  // },
];

// export const allGame = [...slot, ...casino, ...fishing, ...sport];
export const allGame = [
  slot[0],
  slot[1],
  slot[2],
  slot[3],
  slot[4],
  casino[0],
  casino[1],
  casino[2],
  casino[3],
  casino[4],
  ...slot.filter((s, index) => index > 4),
  ...casino.filter((s, index) => index > 4),
  ...fishing,
  ...sport,
];
