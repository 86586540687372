import axios from "axios";
import Swal from "sweetalert2";

const instance = axios.create({
  baseURL: window.location.origin + "/api",
  withCredentials: true,
  timeout: 30000,
});

export const httpClient = instance;

instance.interceptors.request.use(async (config) => {
  // config.headers["Authorization"] = JSON.parse(localStorage.getItem("user"))
  //   ? JSON.parse(localStorage.getItem("user"))?.access_token
  //   : null;

  return config;
});

instance.interceptors.response.use(
  async (response) => {
    return response;
  },
  async (err) => {
    if (err?.status_code == 22000) {
      window.location.reload();
    }
    Swal.fire({
      title: "Error",
      text: "Please contact admin",
      icon: "error",
    });
    return Promise.reject(err);
  }
);

const checkExpiredAccess = (response) => {
  console.log({ response });
  if (response?.data?.status_code == 22000) {
    localStorage.clear();
    return response;
  } else {
    return response;
  }
};

export const get_api = async (endpoint) => {
  try {
    // console.log("ADMIN_ACCOUNT", user);

    let response = await httpClient.get(endpoint);
    // response = checkExpiredAccess(response);

    return {
      data: response.data,
      status: response.data.status_code,
    };
  } catch (err) {
    console.log(err);
    return Promise.reject(err.response);
  }
};

export const get_req_api = async (endpoint, req) => {
  try {
    let response = await httpClient.get(endpoint, req);
    // response = checkExpiredAccess(response);
    return {
      data: response.data,
      status: response.data.status_code,
    };
  } catch (err) {
    return Promise.reject(err.response);
  }
};

export const post_api = async (endpoint, req) => {
  // console.log("req >>>", req);

  try {
    let response = await httpClient.post(endpoint, req);
    // response = checkExpiredAccess(response);
    return {
      data: response.data,
      status: response.data.status_code,
    };
  } catch (err) {
    return Promise.reject(err.response);
  }
};

export const put_api = async (endpoint, req) => {
  // console.log("req >>>", req);

  try {
    let response = await httpClient.put(endpoint, req);
    // response = checkExpiredAccess(response);
    return {
      data: response.data,
      status: response.data.status_code,
    };
  } catch (err) {
    return Promise.reject(err.response);
  }
};

export const delete_api = async (endpoint) => {
  try {
    let response = await httpClient.delete(endpoint);
    // response = checkExpiredAccess(response);
    return {
      data: response.data,
      status: response.data.status_code,
    };
  } catch (err) {
    console.log(err);
    return Promise.reject(err.response);
  }
};
