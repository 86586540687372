import React, { Fragment, useEffect, useState } from "react";
import Button from "./Button";
import { TbReload } from "react-icons/tb";
import { FaSyncAlt } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import { get_api } from "shared/utils/callApiUtils";
import { User } from "shared/interfaces/User";
import Logo from "../assets/logo2.png";

function Navbar() {
  let navigate = useNavigate();
  const [auth, setAuth] = useState(localStorage.getItem("user"));
  const [profile, setProfile] = useState<User>();

  const goToLoginPage = () => {
    navigate("/login");
  };

  const goToRegisterPage = () => {
    navigate("/register");
  };

  const getProfile = () => {
    if (auth) {
      get_api(`users/profile`).then((result) => {
        if (result?.data?.status_code == "20000") {
          setProfile(result?.data?.data);
        } else {
          localStorage.removeItem("user");
          setAuth(undefined);

          document.cookie = "access_token= ";
          navigate("/login");
        }
      });
    }
  };

  useEffect(() => {
    if (auth) {
      getProfile();
    }

    return () => {
      setProfile(undefined);
    };
  }, []);

  return (
    <div className="bg-gradient-to-b from-[#000000] to-[#121212] flex p-4 lg:px-20 justify-between items-center nav-border-bottom">
      <div className="flex flex-row gap-7 items-center text-primary-yellow">
        <img src={Logo} alt="" width={100} className="mt-2" />
        <span className="text-lg font-medium text-white ml-5 hidden lg:block">
          Home
        </span>
        {/* <span className="text-lg font-medium hidden lg:block">Promotion</span>
      <span className="text-lg font-medium hidden lg:block">Get Wealth</span> */}
      </div>

      <div className="flex flex-row gap-2 items-center">
        {!auth && (
          <Fragment>
            <Button
              className="w-19 bg-gradient-to-b from-primary-yellow-light to-primary-yellow-dark py-0 px-2"
              onClick={goToLoginPage}
            >
              <span className="text-xs">เข้าสู่ระบบ</span>
            </Button>
            <Button
              className="w-19 bg-gradient-to-b from-primary-yellow-light to-primary-yellow-dark py-0 px-2"
              onClick={goToRegisterPage}
            >
              <span className="text-xs">สมัครสมาชิก</span>
            </Button>
          </Fragment>
        )}
        {auth && (
          <Fragment>
            <div className="flex flex-row px-1 py-1 items-center rounded-full text-white font-semibold border-2 border-gray-700 bg-[#121212] w-full">
              <img
                src="/images/ic_deposit_money.png"
                alt=""
                className="w-7 h-7"
              />
              <span className="ml-1 text-xs">
                {profile?.amount ? profile.amount.toFixed(2) : 0.0}
              </span>
              <button className="rounded-full" onClick={getProfile}>
                <FaSyncAlt className="w-3 h-3 text-primary-yellow fill-primary-yellow ml-2" />
              </button>
            </div>
            <Link to="/dashboard">
              <img
                src="/images/ic_profile.png"
                alt=""
                className="h-8 w-8 ml-1"
              />
            </Link>
          </Fragment>
        )}
      </div>
    </div>
  );
}

export default Navbar;
