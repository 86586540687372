import React, { useEffect, useState } from "react";
import CircleContainer from "components/CircleContainer";
import { BsPower } from "react-icons/bs";
import { TbArrowBack } from "react-icons/tb";
import { useNavigate } from "react-router-dom";
import bg from "assets/images/dashbg.jpeg";
import LINE_LOGO from "../assets/images/line-logo.png";
import { User } from "shared/interfaces/User";
import banks from "shared/constants/banks";
import Swal from "sweetalert2";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { get_req_api } from "shared/utils/callApiUtils";

interface BankProps {
  bankAccName: string;
  bankName: string;
  bankAccNumber: string;
  bankID: string;
}

function BanksTag() {
  const [depositBank, setDepositBank] = useState<BankProps>();

  useEffect(() => {
    const getBankDeposit = async () => {
      let params = {
        type: "deposit",
      };
      let res = await get_req_api(`banks/users`, { params });
      // console.log(res.data?.data?.data[0]);
      setDepositBank(res.data?.data?.data[0]);
    };

    getBankDeposit();
  }, []);

  // console.log({ item });
  for (let index = 0; index < banks.length; index++) {
    const bank = banks[index];
    if (bank.name == depositBank?.bankName) {
      return (
        <>
          <div className="flex items-center justify-around">
            <img className="h-10" src={bank.path} alt={`bank_${bank.path}`} />
            <div className="flex flex-col text-start">
              <span className="font-normal">{bank?.label}</span>
              <span className="font-normal">{depositBank?.bankAccName}</span>
              <span className="font-normal">{depositBank?.bankAccNumber}</span>
            </div>
            <div className="flex justify-center bg-blue-600 p-2 rounded-md text-white cursor-pointer">
              {/* <button
                onClick={async () => {
                  await copy(depositBank?.bankAccNumber);

                  Swal.fire({
                    text: "คัดลอกแล้ว",
                    showCloseButton: false,
                    showConfirmButton: false,
                    timer: 1000,
                    width: "50%",
                    background: "linear-gradient(180deg,#27006e,#190050)",
                    color: "#fff",
                  });
                }}
              >
                คัดลอก
              </button> */}
              <CopyToClipboard
                text={depositBank?.bankAccNumber}
                onCopy={() => {
                  Swal.fire({
                    text: "คัดลอกแล้ว",
                    showCloseButton: false,
                    showConfirmButton: false,
                    timer: 1000,
                    width: "50%",
                    background: "linear-gradient(180deg,#27006e,#190050)",
                    color: "#fff",
                  });
                }}
              >
                <span>คัดลอก</span>
              </CopyToClipboard>
            </div>
          </div>
        </>
      );
    }
  }
}

function Deposit() {
  let navigate = useNavigate();

  const [user, setUser] = useState<Partial<User>>({});

  const goToPreviousPage = () => {
    navigate(-1);
  };

  const logout = () => {
    localStorage.removeItem("user");

    document.cookie = "access_token= ";
    navigate("/");
  };

  useEffect(() => {
    setUser(JSON.parse(localStorage.getItem("user")));
  }, []);

  return (
    <div className="flex flex-col justify-center items-center bg-black h-screen w-screen">
      <div
        className={`flex flex-col lg:p-6 max-w-[604px] min-w-[300px] h-screen w-screen`}
        style={{
          backgroundImage: `url(${bg})`,
        }}
      >
        <div className="flex w-full justify-between items-start p-3 pb-0">
          <button
            className="text-white flex gap-2 items-center self-center mt-4"
            onClick={goToPreviousPage}
          >
            <TbArrowBack className="w-6 h-6 -scale-y-100" />
            กลับ
          </button>

          <button className="text-white mt-1" onClick={logout}>
            <span className="text-center text-lg text-white flex flex-col items-center">
              <BsPower className="text-primary-yellow w-6 h-6" />
              Logout
            </span>
          </button>
        </div>

        <div className="flex w-full justify-center align-middle">
          <span className="text-xl text-center">
            <span className="text-primary-yellow-light">Username</span>
            <br />
            <span className="text-white">{user?.username || ""}</span>
          </span>
        </div>

        <CircleContainer className="h-48 w-48 mt-8">
          <img src="/images/wallet.png" alt="" className="w-20 h-20" />
          <span className="text-primary-yellow text-center mt-2">Wallet</span>
          <span className="text-white text-xl">{user?.amount || 0.0} THB</span>
        </CircleContainer>

        <div className="flex flex-col justify-center items-center">
          <span className="text-white text-2xl mt-5 font-medium">ฝากเงิน</span>

          <span className="bg-yellow-500 w-30 p-2 rounded-lg m-3">โอนเอง</span>
        </div>

        <div className="w-100 h-30 bg-white p-2 rounded-lg m-3">
          <BanksTag />
        </div>
        <div className="flex justify-center">
          <div className="w-30 h-30 bg-black p-6 rounded-lg m-3 flex justify-center items-center flex-col">
            <span style={{ color: "#fff" }}>
              ฝากแล้ว แจ้งทีมงานเพื่อตรวจสอบยอดทาง Line@
            </span>
            <div
              className="animate-bounce"
              style={{
                width: "55px",
                height: "50px",
                zIndex: 9999,
                cursor: "pointer",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "1svw"
              }}
              onClick={() => {
                setTimeout(() => {
                  window.open("https://line.me/R/ti/p/@858jnqpr", "_blank");
                });
              }}
            >
              <img
                src={LINE_LOGO}
                alt="https://line.me/R/ti/p/@858jnqpr"
                width={35}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Deposit;
