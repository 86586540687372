import Button from "components/Button";
import React, { useEffect, useState } from "react";
import {
  BsBack,
  BsEye,
  BsEyeSlash,
  BsFillPersonFill,
  BsKey,
  BsSkipBackward,
} from "react-icons/bs";
import { FiEye, FiEyeOff, FiKey } from "react-icons/fi";
import { TbArrowBack } from "react-icons/tb";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import useIPaddress from "shared/hooks/useIPaddress";
import { post_api } from "shared/utils/callApiUtils";
import Swal from "sweetalert2";
import Logo from "../assets/logo2.png";

function Login() {
  const [searchParams, setSearchParams] = useSearchParams();

  let navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);
  const [formsLogin, setFormsLogin] = useState({
    username: "",
    password: "",
  });
  const { ipAddress, error } = useIPaddress();

  const goToPreviousPage = () => {
    navigate("/");
  };

  const login = async (e: { preventDefault: () => void }) => {
    e.preventDefault();

    if (formsLogin.username && formsLogin.password) {
      const req = {
        username: formsLogin.username,
        password: formsLogin.password,
      };
      // console.log(req);
      post_api("auth", req).then((result) => {
        // console.log({ result });
        if (result.data.status_code == "20000") {
          localStorage.setItem("user", JSON.stringify(result.data.data));

          Swal.fire({
            // position: "top-end",
            icon: "success",
            title: "Success",
            color: "#fff",
            showConfirmButton: false,
            timer: 1500,
          }).then((result) => {
            /* Read more about handling dismissals below */
            localStorage.setItem("ip_address", ipAddress);
            if (result.dismiss === Swal.DismissReason.timer) {
              // console.log("I was closed by the timer");

              navigate("/games");
            }
          });
        } else {
          Swal.fire({
            // position: "top-end",
            icon: "error",
            title: "Failed",
            color: "#fff",
            text: result.data.error,
            showConfirmButton: false,
            timer: 1500,
          });
        }
      });
    }
  };

  const goToRegisterPage = () => {
    navigate("/register");
  };

  useEffect(() => {
    if (searchParams.get("refId")) {
      let refID = searchParams.get("refId");
      navigate(`/register/?refId=${refID}`);
    }
  }, []);

  return (
    <div className="bg-[#000000] flex flex-row justify-center items-center min-h-screen">
      <form onSubmit={login}>
        <div className="flex flex-col lg:my-12 p-5 px-7 lg:rounded-xl w-screen md:w-fit shadow-md drop-shadow-md h-100">
          <img
            src={Logo}
            alt=""
            style={{ width: "100%", maxWidth: "350px" }}
            className="self-center my-2"
          />

          <div className="flex w-full border border-[#f4b731] rounded-md mt-5">
            <div className="p-2 bg-[#f4b731] rounded-l-md">
              <BsFillPersonFill className="text-[#222] text-xl w-6 h-6 min-w-6 min-h-6 my-auto" />
            </div>
            <input
              type="text"
              placeholder="เบอร์โทรศัพท์"
              className="min-h-full w-full pl-3 border-none outline-none bg-transparent rounded-r-md placeholder-gray-300 text-white"
              onChange={(e) =>
                setFormsLogin({ ...formsLogin, username: e.target.value })
              }
            />
          </div>

          <div className="flex w-full border border-[#f4b731] rounded-md mt-5">
            <div className="p-2.5 bg-[#f4b731] rounded-l-md">
              <FiKey className="text-[#222] text-xl w-5 h-5 min-w-5 min-h-5 my-auto stroke-[2.7px] -scale-x-100 rotate-180" />
            </div>
            <input
              type={showPassword ? "text" : "password"}
              placeholder="รหัสผ่าน"
              className="min-h-full w-full pl-3 border-none outline-none bg-transparent placeholder-gray-300 text-white"
              onChange={(e) =>
                setFormsLogin({ ...formsLogin, password: e.target.value })
              }
            />
            <div className="p-2.5 bg-[#f4b731] rounded-r-md">
              {showPassword ? (
                <BsEye
                  className="text-[#222] text-xl w-5 h-5 min-w-5 min-h-5 my-auto stroke-[0.6px] -scale-x-100 rotate-180"
                  onClick={() => {
                    setShowPassword(false);
                  }}
                />
              ) : (
                <BsEyeSlash
                  className="text-[#222] text-xl w-5 h-5 min-w-5 min-h-5 my-auto stroke-[0.6px] -scale-x-100 rotate-180"
                  onClick={() => {
                    setShowPassword(true);
                  }}
                />
              )}
            </div>
          </div>

          {/* <a
            href="#"
            target="_blank"
            rel="noopener noreferrer"
            className="text-white self-end mt-5"
          >
            Forgot Password?
          </a> */}

          <button
            type="submit"
            className="bg-transparent text-[#f4b731] rounded-full border-2 border-[#f4b731] mt-5 py-2 w-[90%] self-center"
          >
            เข้าสู่ระบบ
          </button>

          {/* or dont have an account text with long dash around it */}
          <div className="flex items-center justify-center mt-5">
            <div className="w-[20%] h-[1px] bg-[#f4b731]"></div>
            <p className="text-white mx-2  font-medium">หรือยังไม่มีบัญชี?</p>
            <div className="w-[20%] h-[1px] bg-[#f4b731]"></div>
          </div>

          {/* <button className='text-[#fff] font-medium bg-gradient-to-b from-primary-yellow-light to-primary-yellow-dark rounded-full border-2 border-[#f4b731] mt-5 py-2 w-[90%] self-center shadow-glow'>
                Register
            </button> */}
          <Button
            className=" mt-5 w-[90%] self-center shadow-glow"
            onClick={goToRegisterPage}
          >
            สมัครสมาชิก
          </Button>

          <button
            className="text-white flex gap-2 items-center self-center mt-4"
            onClick={goToPreviousPage}
          >
            <TbArrowBack className="w-6 h-6 -scale-y-100" />
            กลับไปหน้าแรก
          </button>
        </div>
      </form>
    </div>
  );
}

export default Login;
