import React, { Fragment, useEffect, useState } from "react";
import {
  BsBack,
  BsEye,
  BsEyeSlash,
  BsFillPersonFill,
  BsKey,
  BsSkipBackward,
  BsFillTelephoneFill,
} from "react-icons/bs";
import { TbArrowBack } from "react-icons/tb";
import { useSearchParams, useNavigate } from "react-router-dom";
import useIPaddress from "shared/hooks/useIPaddress";
import { post_api } from "shared/utils/callApiUtils";
import Swal from "sweetalert2";
import OtpInput from "react-otp-input";
import { Listbox, Transition } from "@headlessui/react";
import { Bank } from "shared/interfaces/Bank";
import banks from "shared/constants/banks";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import Logo from "../assets/logo2.png";

function Register() {
  const [searchParams, setSearchParams] = useSearchParams();
  let navigate = useNavigate();
  const { ipAddress, error } = useIPaddress();

  const [displayName, setDisplayName] = useState("");
  const [phone, setPhone] = useState("");
  const [otp, setOtp] = useState("");
  const [pac, setPac] = useState("");
  const [token_id, setTokenId] = useState("");
  const [verifyOtpSuccess, setVerifyOtpSuccess] = useState(false);
  const [selectedBank, setSelectedBank] = useState<Partial<Bank>>(banks[0]);
  const [bankAccName, setBankAccName] = useState("");
  const [bankAccNumber, setBankAccNumber] = useState("");
  const [password, setPassword] = useState("");

  const [refId, setRefId] = useState(null);

  const [verifyBankAccInfo, setVerifyBankAccInfo] = useState(false);
  const [loading, setLoading] = useState(false);

  const goToPreviousPage = () => {
    navigate(-1);
  };

  const goToLoginPage = () => {
    navigate("/login");
  };

  const genOtp = () => {
    if (!phone) {
      Swal.fire({
        icon: "error",
        title: "กรุณากรอกหมายเลขโทรศัพท์",
        color: "#fff",
        showConfirmButton: true,
      });
      return;
    } else {
      const req = {
        phone_number: phone,
      };
      post_api(`otp`, req).then((result) => {
        // console.log("otp", { result });
        if (result.data.status_code === "20000") {
          setPac(result.data.data.pac);
          setTokenId(result.data.data.token_id);
        } else {
          Swal.fire({
            icon: "error",
            title: "กรุณากรอกหมายเลขโทรศัพท์ให้ถูกต้อง",
            color: "#fff",
            showConfirmButton: true,
          });
        }
      });
    }
  };

  const verifyOtp = () => {
    const req = {
      otp: otp,
      pac: pac,
      token_id: token_id,
    };
    post_api(`otp/verify`, req).then((result) => {
      console.log("verify", { result });
      if (result.data.status_code === "20000") {
        // setPac();
        // setPhone();
        // setTokenId();
        setVerifyOtpSuccess(true);
      } else {
        Swal.fire({
          icon: "error",
          title: "Error",
          color: "#fff",
          text: result.data?.error,
        });
      }
    });
  };

  const getBankAccountInfo = async () => {
    const req = {
      toBankAccount: bankAccNumber,
      toBank: selectedBank?.bankCode,
    };
    await post_api(`banks/get-bank-account-info`, req).then((response: any) => {
      // console.log({ response });
      if (response?.data?.status_code === "20000") {
        setBankAccName(response?.data?.data?.bankAccName);
        setVerifyBankAccInfo(true);
      } else {
        Swal.fire(
          "Error",
          `${response?.data?.error} กรุณาตรวจสอบบัญชีธนาคารใหม่`,
          "error"
        );
      }
    });
  };

  const changeBankAccNumber = () => {
    setVerifyBankAccInfo(false);

    setBankAccNumber("");
    setBankAccName("");
  };

  const register = (e: { preventDefault: () => void }) => {
    e.preventDefault();

    const req = {
      displayName: displayName,
      username: phone,
      password: password,
      bankID: selectedBank.id,
      bankName: selectedBank.name,
      bankAccName: bankAccName,
      bankAccNumber: bankAccNumber,
      // trueWalletID: trueWalletID,
      ip: ipAddress,
    };

    // console.log({ req });
    post_api(`users`, req).then(async (result) => {
      // console.log(`Create new user response >`, { result });
      if (result.data.status_code === "20000") {
        if (refId) {
          const req_aff = {
            refid: refId,
            userid: result?.data?.data?._id,
            username: result?.data?.data?.username,
          };
          await post_api(`affiliate/registerwithrefid`, req_aff).then(
            (response) => {
              if (response?.data?.status_code == "20000") {
              }
            }
          );
        }

        Swal.fire({
          icon: "success",
          title: "สมัครบัญชีสำเร็จ",
          color: "#fff",
          showConfirmButton: false,
          timer: 1500,
        }).then((result) => {
          /* Read more about handling dismissals below */
          if (result.dismiss === Swal.DismissReason.timer) {
            // console.log("I was closed by the timer");

            navigate("/login");
          }
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Failed",
          color: "#fff",
          text: result.data.error,
          showConfirmButton: false,
          timer: 1500,
        });
      }
    });
  };

  useEffect(() => {
    if (searchParams.get("refId")) {
      setRefId(searchParams.get("refId"));
    }
  }, []);

  return (
    <div className="bg-[#000000] flex flex-row justify-center items-center min-h-screen">
      <div className="flex flex-col lg:my-12 p-5 px-7 w-screen md:w-fit h-100">
        {/* <img src="/images/logo.jpeg" alt="" className="w-20 self-center my-2" /> */}
        <img
          src={Logo}
          alt=""
          style={{ width: "100%", maxWidth: "350px" }}
          className="self-center my-2"
        />

        <div className="flex flex-col justify-center align-middle">
          <p className="text-2xl text-white text-center">สมัครสมาชิก</p>
        </div>

        <div className="flex w-full border border-[#f4b731] rounded-md mt-5">
          <div className="p-2 bg-[#f4b731] rounded-l-md">
            <BsFillTelephoneFill className="text-[#222] text-xl w-6 h-6 min-w-6 min-h-6 my-auto" />
          </div>
          <input
            type="text"
            placeholder="เบอร์โทร"
            className="min-h-full w-full pl-3 border-none outline-none bg-transparent rounded-r-md placeholder-gray-300 text-white"
            onChange={(e) => {
              setPhone(e.target.value);
              setDisplayName(e.target.value);
            }}
            disabled={verifyOtpSuccess}
          />
        </div>

        {pac && !verifyOtpSuccess && (
          <Fragment>
            <p className="mt-5 text-[#f4b731]">REF: {pac}</p>
            <OtpInput
              value={otp}
              onChange={setOtp}
              numInputs={6}
              isInputNum
              shouldAutoFocus={true}
              containerStyle={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                padding: "1rem",
              }}
              inputStyle={{
                width: "2rem",
                height: "2em",
                fontSize: "18px",
                fontWeight: 600,
              }}
            />
          </Fragment>
        )}

        {!pac && (
          <button
            className="bg-transparent text-[#f4b731] rounded-full border-2 border-[#f4b731] mt-5 py-2 w-[90%] self-center"
            onClick={genOtp}
          >
            ยืนยันเบอร์โทรศัพท์
          </button>
        )}

        {pac && !verifyOtpSuccess && (
          <button
            className="bg-transparent text-[#f4b731] rounded-full border-2 border-[#f4b731] mt-5 py-2 w-[90%] self-center"
            onClick={verifyOtp}
          >
            ยืนยัน OTP
          </button>
        )}

        {verifyOtpSuccess && (
          <Fragment>
            <form onSubmit={register}>
              {/* <div className="flex w-full border border-[#f4b731] rounded-md mt-5">
                <input
                  type="text"
                  placeholder="Username"
                  className="min-h-full w-full p-2 border-none outline-none bg-transparent rounded-r-md placeholder-gray-300 text-white"
                  onChange={(e) => setDisplayName(e.target.value)}
                />
              </div> */}
              <div className="flex w-full border border-[#f4b731] rounded-md mt-5">
                <input
                  type="text"
                  placeholder="ระบุรหัสผ่าน"
                  className="min-h-full w-full p-2 border-none outline-none bg-transparent rounded-r-md placeholder-gray-300 text-white"
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>

              <div className="w-full mt-5">
                <Listbox value={selectedBank} onChange={setSelectedBank}>
                  <div className="relative mt-1">
                    <Listbox.Label className="text-white">ธนาคาร</Listbox.Label>
                    <Listbox.Button className="relative w-full cursor-default rounded-lg bg-white py-2 pl-3 pr-10 text-left shadow-md focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm">
                      <span className="block truncate">
                        {selectedBank.label}
                      </span>
                      <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                        <ChevronUpDownIcon
                          className="h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                      </span>
                    </Listbox.Button>
                    <Transition
                      as={Fragment}
                      leave="transition ease-in duration-100"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <Listbox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                        {banks.map((bank, index) => (
                          <Listbox.Option
                            key={index}
                            className={({ active }) =>
                              `relative cursor-default select-none py-2 pl-10 pr-4 ${
                                active
                                  ? "bg-amber-100 text-amber-900"
                                  : "text-gray-900"
                              }`
                            }
                            value={bank}
                          >
                            {({ selected }) => (
                              <>
                                <span
                                  className={`block truncate ${
                                    selected ? "font-medium" : "font-normal"
                                  }`}
                                >
                                  {bank.label}
                                </span>
                                {selected ? (
                                  <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600">
                                    <CheckIcon
                                      className="h-5 w-5"
                                      aria-hidden="true"
                                    />
                                  </span>
                                ) : null}
                              </>
                            )}
                          </Listbox.Option>
                        ))}
                      </Listbox.Options>
                    </Transition>
                  </div>
                </Listbox>
              </div>

              <div className="flex w-full border border-[#f4b731] rounded-md mt-5">
                <input
                  type="text"
                  value={bankAccNumber}
                  placeholder="หมายเลขบัญชีธนาคาร"
                  className="min-h-full w-full p-2 border-none outline-none bg-transparent rounded-r-md placeholder-gray-300 text-white"
                  onChange={(e) => {
                    setBankAccNumber(e.target.value);
                  }}
                  disabled={verifyBankAccInfo}
                />
                {/* {verifyBankAccInfo && (
                  <button
                    className="bg-[#f4b731] rounded-full border-2 border-[#f4b731] m-3 w-[10%]"
                    onClick={changeBankAccNumber}
                    type="button"
                  >
                    X
                  </button>
                )} */}
              </div>

              {/* {!verifyBankAccInfo && (
                <button
                  className="bg-transparent text-[#f4b731] rounded-full border-2 border-[#f4b731] mt-5 py-2 w-[100%] self-center"
                  onClick={getBankAccountInfo}
                  type="button"
                >
                  1. ตรวจสอบบัญชี
                </button>
              )} */}

              {/* {bankAccName && ( */}
                <div className="flex w-full border border-[#f4b731] rounded-md mt-5">
                  <input
                    type="text"
                    // value=""
                    placeholder="ชื่อบัญชีธนาคาร"
                    className="min-h-full w-full p-2 border-none outline-none bg-transparent rounded-r-md placeholder-gray-300 text-white"
                    onChange={(e) => setBankAccName(e.target.value)}
                  />
                </div>
              {/* )}  */}

              {/* {verifyBankAccInfo && ( */}
                <div className="flex justify-center">
                  <button
                    className="bg-transparent text-[#f4b731] rounded-full border-2 border-[#f4b731] mt-5 py-2 w-[90%] self-center"
                    type="submit"
                  >
                    2. สมัครสมาชิก
                  </button>
                </div>
              {/* )} */}
            </form>
          </Fragment>
        )}

        {refId ? (
          <button
            className="text-white flex gap-2 items-center self-center mt-4"
            onClick={goToLoginPage}
          >
            <TbArrowBack className="w-6 h-6 -scale-y-100" />
            กลับไปหน้าเข้าสู่ระบบ
          </button>
        ) : (
          <button
            className="text-white flex gap-2 items-center self-center mt-4"
            onClick={goToPreviousPage}
          >
            <TbArrowBack className="w-6 h-6 -scale-y-100" />
            กลับไปหน้าแรก
          </button>
        )}
      </div>
    </div>
  );
}

export default Register;
