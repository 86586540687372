import Navbar from "components/Navbar";
import React, { useState, useEffect } from "react";
import LeftBar from "./LeftBar";
import RightGames from "./RightGames";
import { post_api } from "shared/utils/callApiUtils";
import { APIResponse } from "shared/interfaces/APIResponse";
import { Providers } from "shared/interfaces/Providers";
import { SubGame } from "shared/interfaces/SubGame";
import SquareContainer from "components/SquareContainer";

function RightSubGames(props: GamesInterface) {
  if (props.games) {
    props.games.sort((a,b) => a.game_name.toLowerCase().localeCompare(b.game_name.toLowerCase()))
  }
  return (
    <div className="grid grid-cols-4 lg:grid-cols-6 gap-4 grow mx-3 lg:ml-20 xl:ml-28">
      {props.games.map((game, index) => (
        <div
          className="flex flex-col text-white justify-center align-middle text-center"
          style={{
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            overflow: "hidden",
          }}
        >
          <SquareContainer
            key={index}
            providerName={game.game_provider}
            title={game.game_name}
            game_id={game.game_id}
            subGame={true}
          >
            <img
              src={game.game_image}
              alt={game.game_image}
              className="w-16 lg:w-24 h-16 lg:h-24 rounded-xl"
            />
          </SquareContainer>
          <span className="text-sm">{game.game_name}</span>
        </div>
      ))}
    </div>
  );
}

interface GamesInterface {
  category: string;
  games: SubGame[];
}

export default RightSubGames;
