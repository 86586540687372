import { useEffect, useState } from "react";
import axios from "axios";

function useIPaddress() {
  const [ipAddress, setIPAddress] = useState("");
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchIP = async () => {
      try {
        const response = await axios.get("https://api.ipify.org?format=json");
        setIPAddress(response.data.ip);
      } catch (error) {
        setError(error);
      }
    };

    fetchIP();
  }, []);

  return { ipAddress, error };
}

export default useIPaddress;
