import Navbar from "components/Navbar";
import React, { useState, useEffect } from "react";
import LeftBar from "./Games/LeftBar";
import RightGames from "./Games/RightGames";
import { post_api } from "shared/utils/callApiUtils";
import { APIResponse } from "shared/interfaces/APIResponse";
import { Providers } from "shared/interfaces/Providers";
import { allGame } from "shared/constants/list_games";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { SubGame } from "shared/interfaces/SubGame";
import RightSubGames from "./Games/RightSubGames";
import LINE_LOGO from "../assets/images/line-logo.png";

function SubGames() {
  let navigate = useNavigate();
  let { providerName } = useParams();
  const [productId, setProductId] = useState(providerName);

  const [category, setCategory] = React.useState<
    "all" | "casino" | "slot" | "fishing" | string
  >("all");
  // const [category, setCategory] = React.useState<
  //   "all" | "sport" | "casino" | "slot" | "fishing" | string
  // >("all");

  const [gameList, setGameList] = useState<Partial<SubGame[]>>([]);

  const gotoDeposit = () => {
    navigate("/deposit");
  };

  const gotoWithdraw = () => {
    if (JSON.parse(localStorage.getItem("user"))) {
      navigate("/withdraw");
    } else {
      Swal.fire({
        icon: "warning",
        title: "กรุณาเข้าสู่ระบบก่อน",
        color: "#fff",
        showConfirmButton: true,
      });
    }
  };

  useEffect(() => {
    const getGameList = async () => {
      let req = {
        productID: productId,
      };
      post_api(`games/listgames`, req).then((result) => {
        console.log({ result });
        if (result.data.status_code === "20000") {
          setGameList(result.data.data.games);
        } else {
          Swal.fire({
            // position: "top-end",
            icon: "error",
            title: "Failed",
            color: "#fff",
            text: result.data.error,
            showConfirmButton: false,
            timer: 1500,
          });
        }
      });
    };

    getGameList();
  }, [productId]);

  return (
    <div className="bg-[#121212]">
      <Navbar />

      <div
        className="flex flex-col lg:flex-row w-full py-5 lg:py-10 lg:px-24 gap-6 lg:gap-12"
        style={{ minHeight: "100vh" }}
      >
        <div className="w-full lg:w-48">
          <LeftBar setCategory={setCategory} />
        </div>
        <div className="w-full mb-20">
          <RightSubGames category={category} games={gameList} />
        </div>
      </div>

      <div className="lg:hidden fixed bottom-0 w-screen left-0 grid grid-cols-5 bg-[#121212] p-3 py-0 footer-border">
        <button className="flex flex-col justify-center items-center p-1">
          <img
            src="/images/ic_promotion_n.png"
            alt=""
            className="w-8 h-8 col-span-2 my-auto"
          />
          <span className="text-xs text-white">โปรโมชั่น</span>
        </button>

        <button
          className="flex flex-col justify-center items-center p-1"
          onClick={gotoDeposit}
        >
          <img
            src="/images/ic_deposit_n.png"
            alt=""
            className="w-8 h-8 col-span-2 my-auto"
          />
          <span className="text-xs text-white">ฝากเงิน</span>
        </button>

        <button
          className="flex justify-center items-center"
          onClick={() => navigate("/games")}
        >
          <img
            src="/images/ic_play_n.gif"
            alt=""
            className="min-h-[40px] min-w-[40px] h-20 -mt-5 col-span-2"
          />
        </button>

        <button
          className="flex flex-col justify-center items-center p-1"
          onClick={gotoWithdraw}
        >
          <img
            src="/images/ic_withdraw_n.png"
            alt=""
            className="w-8 h-8 col-span-2 my-auto"
          />
          <span className="text-xs text-white">ถอนเงิน</span>
        </button>

        <button
          className="flex flex-col justify-center items-center p-1"
          onClick={() => navigate("../affiliate")}
        >
          <img
            src="/images/ic_recomend_n.png"
            alt=""
            className="w-8 h-8 col-span-2 my-auto"
          />
          <span className="text-xs text-white">ลิงค์รับทรัพย์</span>
        </button>
      </div>

      <div
        className="bg-green-300"
        style={{
          position: "fixed",
          bottom: "8rem",
          right: "0",
          width: "55px",
          height: "50px",
          zIndex: 9999,
          cursor: "pointer",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: "10px 0 0px 10px",
        }}
        onClick={() => {
          setTimeout(() => {
            window.open("https://line.me/R/ti/p/@858jnqpr", "_blank");
          });
        }}
      >
        <img
          src={LINE_LOGO}
          alt="https://line.me/R/ti/p/@858jnqpr"
          width={35}
        />
      </div>
    </div>
  );
}

export default SubGames;
