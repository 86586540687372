import React from 'react'

function CircleContainer(props: CircleContainerProps) {
  return (
    <a href={props?.link} className={`flex flex-col items-center gap-2`}>
        <div className={`rounded-full aspect-square bg-black border-4 border-primary-yellow self-center flex flex-col items-center justify-center ${props?.className}`}>
            {props.children}
        </div>
        <span className="text-white text-lg">
            {props?.title}
        </span>
    </a>
  )
}

interface CircleContainerProps {
    children: React.ReactNode
    title?: string
    className?: string
    link?: string
}

export default CircleContainer