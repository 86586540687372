import { useEffect, useState } from "react";

const useDeviceIsMobile = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const mobileMediaQuery = window.matchMedia("(max-width: 768px)");

    function handleDeviceChange(e) {
      setIsMobile(e.matches);
    }

    // Initial check on hook initialization
    handleDeviceChange(mobileMediaQuery);

    // Attach event listener for changes
    mobileMediaQuery.addEventListener("change", handleDeviceChange);

    // Cleanup the event listener on hook cleanup
    return () => {
      mobileMediaQuery.removeEventListener("change", handleDeviceChange);
    };
  }, []);

  return isMobile;
};

export default useDeviceIsMobile;
