import React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import useFetchData from "shared/hooks/useFetchData";
import { post_api } from "shared/utils/callApiUtils";
import Swal from "sweetalert2";

function GameDirect() {
  let navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const token_auth = searchParams.get("auth_token");

  const { data, loading, error } = useFetchData("games/listprovider", {
    method: "POST",
  });
  console.log("listprovider >", { data });

  React.useEffect(() => {
    if (token_auth) {
      const req = {
        token: token_auth,
      };
      post_api(`auth/token`, req).then((res) => {
        // console.log(res?.data);
        if (res?.data?.status_code === "20000") {
          localStorage.setItem("user", JSON.stringify(res?.data?.data));

          Swal.fire({
            // position: "top-end",
            icon: "success",
            title: "Success",
            color: "#fff",
            showConfirmButton: true,
            timer: 1500,
          }).then((result) => {
            navigate("/games");
          });
        } else {
          Swal.fire({
            title: "Error",
            color: "#fff",
            text: "Please contact admin",
            icon: "error",
          });
        }
      });
    }
  }, [token_auth]);
  return <div className="bg-[#080025] w-screen h-screen"></div>;
}

export default GameDirect;
