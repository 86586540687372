import GameListItem from "components/GameListItem";
import React from "react";

function LeftBar({
  setCategory,
}: {
  setCategory: React.Dispatch<React.SetStateAction<string>>;
}) {
  return (
    <div className="flex flex-row flex-wrap lg:flex-col gap-0 lg:gap-4">
      <GameListItem
        image="/images/all_game.png"
        name="All"
        onClick={() => {
          setCategory("all");
        }}
      />
      {/* <GameListItem
        image="/images/sport_game.png"
        name="Sport"
        onClick={() => {
          setCategory("sport");
        }}
      /> */}
      <GameListItem
        image="/images/casino_game.png"
        name="Casino"
        onClick={() => {
          setCategory("casino");
        }}
      />
      <GameListItem
        image="/images/slot_game.png"
        name="Slot"
        onClick={() => {
          setCategory("slot");
        }}
      />
      {/* <GameListItem image='/images/poker_game_bk.png' name='Card Games' onClick={()=>{
            setCategory('card games')
        }} /> */}
      <GameListItem
        image="/images/lotto_game.png"
        name="Fishing"
        onClick={() => {
          setCategory("fishing");
        }}
      />
    </div>
  );
}

export default LeftBar;
