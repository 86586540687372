import React, { useState, useEffect } from "react";
import bg from "assets/images/dashbg.jpeg";
import { BsPower } from "react-icons/bs";
import CircleContainer from "components/CircleContainer";
import { TbArrowBack } from "react-icons/tb";
import { useNavigate } from "react-router-dom";
import { User } from "shared/interfaces/User";
import { get_api } from "shared/utils/callApiUtils";
import { FaSyncAlt } from "react-icons/fa";
import Swal from "sweetalert2";

function Dashboard() {
  let navigate = useNavigate();
  const [profile, setProfile] = useState<User>();
  const [auth, setAuth] = useState(localStorage.getItem("user"));

  const goToPreviousPage = () => {
    navigate(-1);
  };

  const logout = () => {
    localStorage.removeItem("user");

    document.cookie = "access_token= ";
    navigate("/");
  };

  const getProfile = () => {
    if (auth) {
      // const user = JSON.parse(auth);
      get_api(`users/profile`).then((result) => {
        if (result?.data?.status_code == "20000") {
          setProfile(result?.data?.data);
        } else {
          Swal.fire({
            // position: "top-end",
            icon: "error",
            title: "Session Expired",
            color: "#fff",
            showConfirmButton: true,
          }).then((result) => {
            navigate("/login");
          });
        }
      });
    }
  };

  useEffect(() => {
    if (auth) {
      getProfile();
    } else {
      Swal.fire({
        // position: "top-end",
        icon: "error",
        title: "Session Expired",
        color: "#fff",
        showConfirmButton: true,
      }).then((result) => {
        navigate("/login");
      });
    }

    return () => {
      setProfile(undefined);
    };
  }, []);

  return (
    <div className="flex flex-col justify-center items-center bg-black">
      <div
        className={`flex flex-col`}
        style={{
          minHeight: "100vh",
          maxWidth: "430px",
          width: "100%",
          backgroundImage: `url(${bg})`,
        }}
      >
        <div className="flex w-full justify-between items-start p-3 pb-0">
          <button
            className="text-white flex gap-2 items-center self-center mt-4"
            onClick={goToPreviousPage}
          >
            <TbArrowBack className="w-6 h-6 -scale-y-100" />
            กลับ
          </button>

          <button className="text-white mt-1" onClick={logout}>
            <span className="text-center text-lg text-white flex flex-col items-center">
              <BsPower className="text-primary-yellow w-6 h-6" />
              Logout
            </span>
          </button>
        </div>

        <div className="flex w-full justify-center align-middle mt-5">
          <span className="text-xl text-center">
            <span className="text-primary-yellow-light">Username</span>
            <br />
            <span className="text-white">{profile?.username}</span>
          </span>
        </div>

        <CircleContainer className="h-40 w-40 mt-4">
          <img src="/images/wallet.png" alt="" className="w-20 h-20" />
          <span className="text-primary-yellow text-center mt-2">
            กระเป๋าเงิน
          </span>
          <span className="text-white text-md">
            {profile?.amount ? profile.amount.toFixed(2) : 0.0} THB
          </span>
          <span className="mt-0">
            <button className="rounded-full" onClick={getProfile}>
              <FaSyncAlt className="w-4 h-4 text-primary-yellow fill-primary-yellow ml-2 mr-2" />
            </button>
          </span>
        </CircleContainer>

        <div className="grid grid-cols-3 gap-8 self-center mt-12 mx-2">
          <button onClick={() => navigate("/deposit")}>
            <CircleContainer title="ฝากเงิน" className="w-15 p-3">
              <img
                src="/images/ic_deposit_n.png"
                alt=""
                className="w-10 h-10"
              />
            </CircleContainer>
          </button>

          <button onClick={() => navigate("/games")}>
            <CircleContainer
              title="เข้าเล่น"
              className="w-24 p-4 -mt-10 play-btn-shadow"
            >
              <img src="/images/ic_play_n.gif" alt="" className="w-18 h-18" />
            </CircleContainer>
          </button>

          <button onClick={() => navigate("/withdraw")}>
            <CircleContainer title="ถอนเงิน" className="w-15 p-3">
              <img
                src="/images/ic_withdraw_n.png"
                alt=""
                className="w-10 h-10"
              />
            </CircleContainer>
          </button>
        </div>

        <div className="grid grid-cols-3 mt-12">
          <CircleContainer title="โปรโมชั่น" className="w-15 p-3" link="">
            <img
              src="/images/ic_promotion_n.png"
              alt=""
              className="w-10 h-10"
            />
          </CircleContainer>

          {/* <CircleContainer title="Activity" className="w-15 p-3" link="/">
            <img
              src="/images/ic_activity.png"
              alt=""
              className="w-10 lg:w-16 h-10 lg:h-16"
            />
          </CircleContainer> */}

          <button onClick={() => navigate("/affiliate")}>
            <CircleContainer title="ลิงค์รับทรัพย์" className="w-15 p-3">
              <img
                src="/images/ic_recomend_n.png"
                alt=""
                className="w-10 lg:w-16 h-10 lg:h-16"
              />
            </CircleContainer>
          </button>

          <button onClick={() => navigate("/account")}>
            <CircleContainer title="โปรไฟล์" className="w-15 p-3">
              <img
                src="/images/ic_withdraw_n.png"
                alt=""
                className="w-10 h-10"
              />
            </CircleContainer>
          </button>

          {/* <CircleContainer title="Announce" className="w-15 p-3" link="/">
            <img
              src="/images/ic_publish.png"
              alt=""
              className="w-10 lg:w-16 h-10 lg:h-16"
            />
          </CircleContainer> */}

          {/* <CircleContainer title="Contact" className="w-15 p-3" link="/">
            <img
              src="/images/ic_contact.png"
              alt=""
              className="w-10 lg:w-16 h-10 lg:h-16"
            />
          </CircleContainer> */}

          {/* <CircleContainer title="AI Formulas" className="w-15 p-3" link="/">
            <img
              src="/images/slot_game.png"
              alt=""
              className="w-10 lg:w-16 h-10 lg:h-16"
            />
          </CircleContainer> */}
        </div>

        <div className="grid grid-cols-3 mt-12">
          <button onClick={() => navigate("/history")}>
            <CircleContainer title="ประวัติฝาก/ถอน" className="w-15 p-3">
              <img src="/images/ic_history.png" alt="" className="w-10 h-10" />
            </CircleContainer>
          </button>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
