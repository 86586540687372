import Login from "pages/Login";
import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Dashboard from "pages/dashboard";
import Games from "pages/Games";
import Register from "pages/Register";
import SubGames from "pages/SubGames";
import Deposit from "pages/Deposit";
import Withdraw from "pages/Withdraw";
import Account from "pages/Account";
import Affiliate from "pages/Affiliate";
import GameDirect from "pages/GameDirect";
import HistoryDepositWithdraw from "pages/HistoryDepositWithdraw";

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/games" element={<Games />} />
          <Route path="/sub-games/:providerName" element={<SubGames />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/deposit" element={<Deposit />} />
          <Route path="/withdraw" element={<Withdraw />} />
          <Route path="/account" element={<Account />} />
          <Route path="/affiliate" element={<Affiliate />} />
          <Route path="/direct" element={<GameDirect />} />
          <Route path="/history" element={<HistoryDepositWithdraw />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
