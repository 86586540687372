import Image1 from "../../assets/images/banks/kasikorn-bank.png";
import Image2 from "../../assets/images/banks/scb-bank.png";
import Image3 from "../../assets/images/banks/krungthai-bank.png";
import Image4 from "../../assets/images/banks/tmb-bank.png";
import Image5 from "../../assets/images/banks/krungthep-bank.png";
import Image6 from "../../assets/images/banks/kungsri-bank.png";
import Image7 from "../../assets/images/banks/baac_icon.png";
import Image8 from "../../assets/images/banks/gsb.png";
import Image9 from "../../assets/images/banks/citi-bank.png";
import Image10 from "../../assets/images/banks/kkp_bank.webp";
import Image11 from "../../assets/images/banks/cimb_bank.png";
import Image12 from "../../assets/images/banks/tisco_bank.jpeg";
import Image14 from "../../assets/images/banks/uob_bank.png";
import Image15 from "../../assets/images/banks/lh_bank.png";
import Image16 from "../../assets/images/banks/icbc_bank.png";
import Image17 from "../../assets/images/banks/isbt_bank.jpeg";
import Image18 from "../../assets/images/banks/ghb-bank.png";
import Image19 from "../../assets/images/banks/db-bank-logo.png";
import Image20 from "../../assets/images/banks/HSBC-bank.png";
import Image22 from "../../assets/images/banks/TCRB-bank.png";

const banks = [
  {
    id: 1,
    label: "ธนาคารกสิกรไทย",
    path: Image1,
    name: "KBANK",
    bankCode: "004",
  },
  {
    id: 2,
    label: "ธนาคารไทยพาณิชย์",
    path: Image2,
    name: "SCB",
    bankCode: "014",
  },
  {
    id: 3,
    label: "ธนาคารกรุงไทย",
    path: Image3,
    name: "KTB",
    bankCode: "006",
  },
  {
    id: 4,
    label: "ธนาคารทหารไทย",
    path: Image4,
    name: "TTB",
    bankCode: "011",
  },
  {
    id: 5,
    label: "ธนาคารกรุงเทพ",
    path: Image5,
    name: "BBL",
    bankCode: "002",
  },
  {
    id: 6,
    label: "ธนาคารกรุงศรี",
    path: Image6,
    name: "BAY",
    bankCode: "025",
  },
  {
    id: 7,
    label: "ธนาคารเพื่อการเกษตรและสหกรณ์การเกษตร",
    path: Image7,
    name: "BAAC",
    bankCode: "034",
  },
  {
    id: 8,
    label: "ธนาคารออมสิน",
    path: Image8,
    name: "GSB",
    bankCode: "030",
  },
  {
    id: 9,
    label: "ธนาคารซิตี้แบงก์",
    path: Image9,
    name: "CITI",
    bankCode: "017",
  },
  // {
  //   id: 10,
  //   label: "ธนาคารเกียรตินาคินภัทร",
  //   path: Image10,
  //   name: "KKP",
  //   bankCode: "069",
  // },
  {
    id: 11,
    label: "ธนาคารซีไอเอ็มบีไทย",
    path: Image11,
    name: "CIMB",
    bankCode: "022",
  },
  {
    id: 12,
    label: "ธนาคารเกียรตินาคินภัทร",
    path: Image10,
    name: "KK",
    bankCode: "069",
  },
  {
    id: 13,
    label: "ธนาคารทิสโก้",
    path: Image12,
    name: "TSCO",
    bankCode: "067",
  },
  {
    id: 14,
    label: "ธนาคารยูโอบี",
    path: Image14,
    name: "UOB",
    bankCode: "024",
  },
  {
    id: 15,
    label: "ธนาคารแลนด์ แอนด์ เฮ้าส์",
    path: Image15,
    name: "LHBANK",
    bankCode: "073",
  },
  {
    id: 16,
    label: "ธนาคารไอซีบีซี (ไทย)",
    path: Image16,
    name: "ICBCT",
    bankCode: "070",
  },
  {
    id: 17,
    label: "ธนาคารอิสลามแห่งประเทศไทย",
    path: Image17,
    name: "ISBT",
    bankCode: "066",
  },
  {
    id: 18,
    label: "ธนาคารอาคารสงเคราะห์",
    path: Image18,
    name: "GHB",
    bankCode: "033",
  },
  {
    id: 19,
    label: "ธนาคารดอยช์แบงก์",
    path: Image19,
    name: "DB",
    bankCode: "032",
  },
  {
    id: 20,
    label: "ธนาคารฮ่องกงและเซี่ยงไฮ้ จำกัด",
    path: Image20,
    name: "HSBC",
    bankCode: "031",
  },
  // {
  //   id: 21,
  //   label: "ธนาคารมิซูโฮ",
  //   path: Image21,
  //   name: "MHCB",
  //   bankCode: "039",
  // },
  {
    id: 22,
    label: "ธนาคารไทยเครดิต เพื่อรายย่อย จำกัด",
    path: Image22,
    name: "TCRB",
    bankCode: "071",
  },
];

export default banks;
