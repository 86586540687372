import React, { useEffect, useState } from "react";
import CircleContainer from "components/CircleContainer";
import { BsPower } from "react-icons/bs";
import { TbArrowBack } from "react-icons/tb";
import { useNavigate } from "react-router-dom";
import bg from "assets/images/dashbg.jpeg";
import { User } from "shared/interfaces/User";
import { get_api, post_api } from "shared/utils/callApiUtils";
import { FaSyncAlt } from "react-icons/fa";
import Swal from "sweetalert2";

function Withdraw() {
  let navigate = useNavigate();
  const [auth, setAuth] = useState(localStorage.getItem("user"));
  const [profile, setProfile] = useState<User>();
  const [withdraw, setWithdraw] = useState<number>(null);

  const amountValue = [300, 500, 700, 1000, 1200, 1500];

  const goToPreviousPage = () => {
    navigate(-1);
  };

  const withdrawMoney = async (e: any) => {
    e.preventDefault();

    if (withdraw > 0) {
      Swal.fire({
        icon: "warning",
        text: "กรุณายืนยันการถอนเงิน!",
        color: "#fff",
        showCancelButton: true,
        confirmButtonText: "ยืนยัน",
        confirmButtonColor: "#27006e",
        denyButtonText: `ยกเลิก`,
      }).then(async (result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          let req = {
            username: profile?.username,
            accountNumber: profile?.bankAccNumber,
            amount: withdraw,
          };
          let response = await post_api(
            `bank-transaction/request-withdraw`,
            req
          );
          console.log({ response });
          if (response?.data?.status_code == "20000") {
            Swal.fire({
              // position: "top-end",
              icon: "success",
              title: `ผลการถอนเงินสำเร็จ`,
              text: "",
              showConfirmButton: false,
              timer: 1500,
            }).then(() => {
              navigate(0);
            });
          } else {
            Swal.fire(
              `ผลการถอนเงินไม่สำเร็จ`,
              `${response?.data?.error}`,
              "error"
            );
          }
        }
      });
    }
  };

  const logout = () => {
    localStorage.removeItem("user");

    document.cookie = "access_token= ";
    navigate("/");
  };

  const getProfile = () => {
    if (auth) {
      get_api(`users/profile`).then((result) => {
        if (result?.data?.status_code == "20000") {
          setProfile(result?.data?.data);
        } else {
          localStorage.removeItem("user");
          setAuth(undefined);

          document.cookie = "access_token= ";
          Swal.fire({
            // position: "top-end",
            icon: "error",
            title: "Session Expired",
            color: "#fff",
            showConfirmButton: true,
          }).then((result) => {
            navigate("/login");
          });
        }
      });
    }
  };

  useEffect(() => {
    if (auth) {
      getProfile();
    } else {
      Swal.fire({
        // position: "top-end",
        icon: "error",
        title: "Session Expired",
        color: "#fff",
        showConfirmButton: true,
      }).then((result) => {
        navigate("/login");
      });
    }

    return () => {
      setProfile(undefined);
    };
  }, []);

  const valueOnChnage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setWithdraw(parseFloat(event.target.value));
  };

  const withdrawValue = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    value: string | number
  ) => {
    event.preventDefault();
    console.log(value);

    let withdrawData = withdraw;

    if (!withdraw) {
      setWithdraw(0);
      withdrawData = 0;
    }

    if (typeof value === "string") {
      if (value === "+") {
        setWithdraw(withdrawData + 100);
      } else {
        if (withdraw - 100 < 0) {
          setWithdraw(0);
          return;
        }
        setWithdraw(withdrawData - 100);
      }
    } else {
      setWithdraw(withdrawData + value);
    }
  };

  return (
    <div className="flex flex-col justify-center items-center bg-black h-screen">
      <div
        className={`flex flex-col lg:p-6 max-w-[604px] min-w-[300px] h-full w-full`}
        style={{
          backgroundImage: `url(${bg})`,
        }}
      >
        <div className="flex w-full justify-between items-start p-3 pb-0">
          <button
            className="text-white flex gap-2 items-center self-center mt-4"
            onClick={goToPreviousPage}
          >
            <TbArrowBack className="w-6 h-6 -scale-y-100" />
            กลับ
          </button>

          <button className="text-white mt-1" onClick={logout}>
            <span className="text-center text-lg text-white flex flex-col items-center">
              <BsPower className="text-primary-yellow w-6 h-6" />
              Logout
            </span>
          </button>
        </div>

        <div className="flex w-full justify-center align-middle">
          <span className="text-xl text-center">
            <span className="text-primary-yellow-light">Username</span>
            <br />
            <span className="text-white">{profile?.username}</span>
          </span>
        </div>

        <CircleContainer className="h-56 w-56 mt-8">
          <img src="/images/wallet.png" alt="" className="w-20 h-20" />
          <span className="text-primary-yellow text-center mt-2">Wallet</span>
          <span className="text-white text-xl">
            {profile?.amount ? profile.amount.toFixed(2) : 0.0} THB
          </span>
          <span className="mt-5">
            <button className="rounded-full" onClick={getProfile}>
              <FaSyncAlt className="w-4 h-4 text-primary-yellow fill-primary-yellow ml-2 mr-2" />
            </button>
          </span>
        </CircleContainer>

        <form onSubmit={withdrawMoney}>
          <div className="grid grid-cols-12 gap-4 align-middle mt-5">
            <div className="col-span-2 text-center">
              <button
                onClick={(event) => {
                  withdrawValue(event, "-");
                }}
                className="bg-yellow-500 hover:bg-yellow-700 text-white font-bold py-2 px-4 border-b-4 border-yellow-800 hover:border-yellow-900 rounded"
              >
                -
              </button>
            </div>
            <div className="col-span-8 text-center">
              <input
                name="price"
                id="price"
                className="block w-full rounded-md border-0 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 h-10 p-2"
                placeholder="0.00"
                onChange={valueOnChnage}
                type="number"
                // min="100"
                value={withdraw}
              />
            </div>
            <div className="col-span-2 text-center">
              <button
                onClick={(event) => {
                  withdrawValue(event, "+");
                }}
                className="bg-yellow-500 hover:bg-yellow-700 text-white font-bold py-2 px-4 border-b-4 border-yellow-800 hover:border-yellow-900 rounded"
              >
                +
              </button>
            </div>
          </div>
          <div className="grid grid-cols-3 gap-x-12 gap-y-5 mt-12 mx-10 self-center">
            {amountValue.map((value, index) => {
              return (
                <button
                  key={index}
                  onClick={(event) => {
                    withdrawValue(event, value);
                  }}
                  className="bg-yellow-500 hover:bg-yellow-700 text-white font-bold py-2 px-4 border-b-4 border-yellow-800 hover:border-yellow-900 rounded"
                >
                  {value}
                </button>
              );
            })}
          </div>
          <div className="flex flex-col align-middle mt-10">
            <button
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full"
              type="submit"
            >
              ถอนเงิน
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Withdraw;
