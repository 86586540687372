import { useState, useEffect } from "react";
import axios from "axios";
import Swal from "sweetalert2";

const instance = axios.create({
  // baseURL: process.env.REACT_APP_BASE_API_URL,
  baseURL: window.location.origin + "/api",
  withCredentials: true,
  timeout: 30000,
});

instance.interceptors.request.use(async (config) => {
  // config.headers["Authorization"] = JSON.parse(localStorage.getItem("user"))
  //   ? JSON.parse(localStorage.getItem("user"))?._id
  //   : null;

  return config;
});

instance.interceptors.response.use(
  async (response) => {
    return response;
  },
  async (err) => {
    Swal.fire({
      title: "Error",
      color: "#fff",
      text: "Please contact admin",
      icon: "error",
    });
    return Promise.reject(err);
  }
);

export const httpClient = instance;

const useFetchData = (url, options = {}) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);

      try {
        const response = await httpClient({
          method: options.method || "GET",
          url,
          params: options.params || {},
          headers: options.headers || {},
          data: options.data,
        });

        setData(response.data);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [url, options.method, options.params, options.headers, options.data]);

  return { data, loading, error };
};

export default useFetchData;
