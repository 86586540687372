import React, { useState, useEffect } from "react";
import useDeviceIsMobile from "shared/hooks/useDeviceIsMobile";
import { post_api } from "shared/utils/callApiUtils";
import Swal from "sweetalert2";

function SquareContainer(props: SquareContainerProps) {
  const [username, setUsername] = useState("");
  const ipAddress = localStorage.getItem("ip_address");
  const isMobile = useDeviceIsMobile();

  const openGame = async (providerName: string) => {
    const productId = providerName;

    if (!username) {
      Swal.fire({
        // position: "top-end",
        icon: "error",
        title: "Failed",
        text: "กรุณา login ก่อน",
        showConfirmButton: false,
        color: "#fff",
        timer: 1500,
      }).then((result) => {
        // console.log("reload");
        if (result.dismiss === Swal.DismissReason.timer) {
          // window.location.reload();
        }
      });
    } else {
      if (props.subGame === false) {
        // ดึงเกม list ของ game
        if (productId) {
          let req = {
            productID: productId,
          };
          await post_api(`games/listgames`, req).then(async (result) => {
            // console.log({ result });
            // check it's olny game or have list game
            if (result.data.status_code === "20000") {
              const new_productId = productId.replace(" ", "_");
              setTimeout(() => {
                window.open(`sub-games/${new_productId}`, "_blank");
              });
              // window.open(`sub-games/${new_productId}`, "_blank");
            } else {
              const req: any = {
                username: username,
                productID: providerName,
                gameCode: "0",
                isMobileLogin: isMobile || false,
                sessionToken: "d4be70d1-349f-4fc1-a955-35d2a4bff244",
                betLimit: [],
                ip: ipAddress,
              };
              await post_api(`games/playgame`, req).then((result) => {
                // console.log({ result });
                if (result.data.status_code === "20000") {
                  const game_url = result.data.data.login;
                  setTimeout(() => {
                    window.open(game_url, "_blank");
                  });

                  // window.open(game_url, "_blank");
                } else {
                  Swal.fire({
                    // position: "top-end",
                    icon: "error",
                    title: "Failed",
                    color: "#fff",
                    text: result.data.error,
                    showConfirmButton: false,
                    timer: 1500,
                  });
                }
              });
            }
          });
        } else {
          Swal.fire({
            // position: "top-end",
            icon: "error",
            title: "Failed",
            color: "#fff",
            text: "No productId in list games.",
            showConfirmButton: false,
            timer: 1500,
          });
        }
      } else {
        // เล่นเกม subgame
        const req: any = {
          username: username,
          productID: providerName,
          gameCode: props.game_id,
          isMobileLogin: isMobile || false,
          sessionToken: "d4be70d1-349f-4fc1-a955-35d2a4bff244",
          betLimit: [],
          ip: ipAddress,
        };

        post_api(`games/playgame`, req).then((result) => {
          // console.log({ result });
          if (result.data.status_code === "20000") {
            const game_url = result.data.data.login;
            setTimeout(() => {
              window.open(game_url, "_blank");
            });

            // window.open(game_url, "_blank");
          } else {
            Swal.fire({
              // position: "top-end",
              icon: "error",
              title: "Failed",
              color: "#fff",
              text: result.data.error,
              showConfirmButton: false,
              timer: 1500,
            });
          }
        });
      }
    }
  };

  useEffect(() => {
    const username = JSON.parse(localStorage.getItem("user"))
      ? JSON.parse(localStorage.getItem("user"))?.username
      : null;
    setUsername(username);
  }, [username]);

  return (
    <button
      className={`flex flex-col items-center gap-2 col-span-1`}
      onClick={() => openGame(props.providerName)}
    >
      <div
        className={`rounded-xl bg-gradient-to-b from-black to-[#312b2b] border-2 border-primary-yellow self-center flex flex-col items-center justify-center ${props?.className}`}
      >
        {props.children}
      </div>
    </button>
  );
}

interface SquareContainerProps {
  children: React.ReactNode;
  title?: string;
  className?: string;
  link?: string;
  providerName?: string;
  game_id?: string;
  subGame: boolean;
}

SquareContainer.defaultProps = {
  // className: 'w-44 h-44',
  children: <></>,
};

export default SquareContainer;
