import React, { useEffect, useState } from "react";
import CircleContainer from "components/CircleContainer";
import { BsPower } from "react-icons/bs";
import { TbArrowBack } from "react-icons/tb";
import { useNavigate } from "react-router-dom";
import bg from "assets/images/dashbg.jpeg";
import { User } from "shared/interfaces/User";
import { get_api } from "shared/utils/callApiUtils";
import { FaSyncAlt } from "react-icons/fa";
import Swal from "sweetalert2";
import Bank from "shared/constants/banks";

interface BankProps {
  bankAccName: string;
  bankAccNumber: string;
  path: string;
  label: string;
}

function BanksTag(props: BankProps) {
  return (
    <>
      <div className="flex items-center justify-evenly">
        <img className="h-10" src={props?.path} alt={`bank_${props?.path}`} />
        <div className="flex flex-col text-start">
          <span className="font-normal">{props?.label}</span>
          <span className="font-normal">{props?.bankAccName}</span>
          <span className="font-normal">{props?.bankAccNumber}</span>
        </div>
      </div>
    </>
  );
}

function Account() {
  let navigate = useNavigate();
  const [auth, setAuth] = useState(localStorage.getItem("user"));
  const [profile, setProfile] = useState<User>();
  const [accDetail, setAccDetail] = useState<any>(null);

  const goToPreviousPage = () => {
    navigate(-1);
  };

  const logout = () => {
    localStorage.removeItem("user");

    document.cookie = "access_token= ";
    navigate("/");
  };

  const getProfile = () => {
    if (auth) {
      get_api(`users/profile`).then((result) => {
        if (result?.data?.status_code == "20000") {
          setProfile(result?.data?.data);
          // console.log(
          //   Bank.find((bank) => bank.name == result?.data?.data?.bankName)
          // );
          setAccDetail(
            Bank.find((bank) => bank.name == result?.data?.data?.bankName)
          );
        } else {
          localStorage.removeItem("user");
          setAuth(undefined);

          document.cookie = "access_token= ";
          Swal.fire({
            // position: "top-end",
            icon: "error",
            title: "Session Expired",
            color: "#fff",
            showConfirmButton: true,
          }).then((result) => {
            navigate("/login");
          });
        }
      });
    }
  };

  useEffect(() => {
    if (auth) {
      getProfile();
    } else {
      Swal.fire({
        // position: "top-end",
        icon: "error",
        title: "Session Expired",
        color: "#fff",
        showConfirmButton: true,
      }).then((result) => {
        navigate("/login");
      });
    }

    return () => {
      setProfile(undefined);
    };
  }, []);

  return (
    <div className="flex flex-col justify-center items-center bg-black">
      <div
        className={`flex flex-col`}
        style={{
          minHeight: "100vh",
          maxWidth: "430px",
          width: "100%",
          backgroundImage: `url(${bg})`,
        }}
      >
        <div className="flex w-full justify-between items-start p-3 pb-0">
          <button
            className="text-white flex gap-2 items-center self-center mt-4"
            onClick={goToPreviousPage}
          >
            <TbArrowBack className="w-6 h-6 -scale-y-100" />
            กลับ
          </button>

          <button className="text-white mt-1" onClick={logout}>
            <span className="text-center text-lg text-white flex flex-col items-center">
              <BsPower className="text-primary-yellow w-6 h-6" />
              Logout
            </span>
          </button>
        </div>

        <div className="flex w-full justify-center align-middle">
          <span className="text-xl text-center">
            <span className="text-primary-yellow-light">Username</span>
            <br />
            <span className="text-white">{profile?.username}</span>
          </span>
        </div>

        <CircleContainer className="h-56 w-56 mt-8">
          <img src="/images/wallet.png" alt="" className="w-20 h-20" />
          <span className="text-primary-yellow text-center mt-2">Wallet</span>
          <span className="text-white text-xl">
            {profile?.amount ? profile.amount.toFixed(2) : 0.0} THB
          </span>
          <span className="mt-5">
            <button className="rounded-full" onClick={getProfile}>
              <FaSyncAlt className="w-4 h-4 text-primary-yellow fill-primary-yellow ml-2 mr-2" />
            </button>
          </span>
        </CircleContainer>

        <div className="grid grid-cols-12 gap-4 align-middle mt-10">
          <div className="col-span-12 self-center">
            <div className="w-100 h-30 bg-white p-2 rounded-lg m-3">
              <BanksTag
                bankAccName={profile?.bankAccName}
                bankAccNumber={profile?.bankAccNumber}
                path={accDetail?.path}
                label={accDetail?.label}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Account;
