import React from "react";

function GameListItem(props: {
  image: string;
  name: string;
  onClick?: () => void;
}) {
  return (
    <button className="rounded-full">
      <div
        className="bg-transparent lg:bg-[#040012] rounded-2xl p-2 lg:p-3 flex flex-col lg:flex-row items-center gap-2 lg:gap-5 text-primary-yellow text-sm lg:text-lg font-medium w-fit lg:w-72"
        onClick={props.onClick}
      >
        <img src={props.image} alt="" className="w-8 lg:w-14 h-8 lg:h-14" />
        <span className="flex-wrap break-words">{props.name}</span>
      </div>
    </button>
  );
}

export default GameListItem;
